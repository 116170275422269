<template>
  <div>
    <h1 class="mb-0">Change Password</h1>
   
    
     <error v-if="error" :error="error" />
    <form class="mt-4" @submit.prevent="handleSubmit">
      <div class="form-group">
        <input
          type="password"
          v-model="user.password"
          class="form-control mb-0"
          id="password"
          placeholder="Enter Password"
        />
      </div>
        <div class="form-group">
        <input
          type="password"
          v-model="user.password_confirmation"
          class="form-control mb-0"
          id="password_confirmation"
          placeholder="Enter Confirm Password"
        />
      </div>
      <div class="d-inline-block w-100">
        <button type="submit" class="btn btn-bluish mt-4 w-auto px-4">
          Change Password
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import axios from "../../../components/axios";
import Error from "../../../components/socialvue/error/Error";

export default {
  name: 'ResetPassword',
  components: {
    Error,
  },
  data(){
    return{
      user: {
        password: "",
        password_confirmation :'',
        token: this.$route.params.token,
      },
        error:"",
    }
  },
   mounted() {
    
  },
 methods: {
    async handleSubmit() {
        // this.token = this.$route.params.token
      const config = {
        method: "post",
        url: "resetpassword",
        data: this.user,
      };
      await axios(config)
        .then((res) => {
          this.$router.push({ name: 'auth1.sign-in1' });
        })
        .catch((err) => {
          console.log(err.response.data.errors['password'][0]);
          this.error = err.response.data.errors['password'][0]
        });
    },
  },
  
}
</script>
